export const environment = {
    production: true,
    edgeService: 'https://api.gbs-ls.ceres.siemens.cloud',
    scdUrl: 'https://api.scd.suites.siemens.cloud',
    siteCollection: '/test/10000120',
    languageAssetsPath: './assets/language/',
    tracking: false,
    whitelist: ['api.gbs-ls.ceres.siemens.cloud'],
    authentication: {
        unauthorizedRoute: '/unauthorized',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'oc84aprufpf249n8m8m570h74',
        authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM',
        authWellknownEndpointUrl:
            'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM/.well-known/openid-configuration',
        scope: 'openid profile email',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: true,
        renewTimeBeforeTokenExpiresInSeconds: 300,
        renewUserInfoAfterTokenRenew: true,
        ignoreNonceAfterRefresh: true
    },
    apiKey: 'zHqbqgvjVp5gnyTTHtyaI2z9fGS1c4cN980LqTzc',
    tenant: 'GBS LS',
    guestAccess: false,
    links: {
        documentOfferTemplate: '',
        documentOfferTemplateEN: ''
    },
    junoApp: 'https://juno.suites.siemens.cloud',
    junoApiURI: 'https://api.mail.wcs-apps.siemens.cloud/v2.0',
    junoAppId: '73a1c4a89ae54505824b6f0f4fcc78b3'
};
